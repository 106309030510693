*{
   padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background-color: #CCC1C1;
 
}

